import React, {useCallback, useEffect, useMemo, useState} from 'react';
import MessageBox, {MessageModel} from '../components/MessageBox';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';
import {pipe} from 'fp-ts/lib/function';
import {useRecoilState, useRecoilValue} from 'recoil';
import {userState} from '../model/state/userState';
import {
  additionalStudyState,
  nextStudyProgressState,
  nextStudyStatState,
  useUpdateStepState,
} from '../model/state/nextStepState';
import StatService from '../service/StatService';
import {User} from '../model/domain/User';
import {debug} from '../util/logger';
import {NextStep} from '../model/domain/NextStep';
import {BSTask} from '../model/types';
import {BSTaskFromIO, executeTask, ignoreTaskEither} from '../util/bs-fp';
import StudyService from '../service/StudyService';
import UserService from '../service/UserService';
import {BSNotification} from '../model/domain/BSNotification';
import styles from '../styles/pages/WelcomeBack.module.css';
import {useNavigate} from 'react-router-dom';
import {NotificationPopup} from '../components/NotificationPopup';
import {useShortcuts} from '../hooks/useShortcuts';
import {notificationsState} from '../model/state/notificationsState';
import {useError} from '../model/state/errorState';
import {BSError} from '../model/error/BSError';
import {Button} from '@mui/material';
import {BSTheme} from '../AppTheme';
import makeStyles from '@mui/styles/makeStyles';
import {ReviewAdditionalCards} from '../model/domain/StudyStat';

interface WelcomeBackProps {}

const useStyles = makeStyles((theme: BSTheme) => ({
  button: {
    minWidth: '300px',
    minHeight: '50px',
    borderRadius: '50px',
    padding: '0px 75px',
    borderColor: '#807F84',
    backgroundColor: 'inherit',
    boxShadow: 'none',
    '&.Mui-selected': {
      backgroundColor: '#D6D6D6',
    },
    '&:hover': {
      backgroundColor: theme.palette.extended.cardAnswerButtonHoverColor,
    },
    '&:active': {
      backgroundColor: theme.palette.extended.cardAnswerButtonHoverColor,
    },
    fontFamily: 'RobotoFlex',
    fontWeight: 700,
    fontSize: '20px',
    color: '#434343',
  },
}));

const WelcomeBack: React.FC<WelcomeBackProps> = ({}) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const [shortcutsNotificationVisible, setShortcutsNotificationVisible] =
    useState(false);

  const user = useRecoilValue(userState);
  const setNextStep = useUpdateStepState();
  const nextStudyStat = useRecoilValue(nextStudyStatState);
  const nextStudyProgress = useRecoilValue(nextStudyProgressState);
  const additionalCards = useRecoilValue(additionalStudyState);
  const [notification, setNotification] = useState<O.Option<string>>(O.none);
  const [inProgress, setInProgress] = useState(true);

  const hasAssignedCards = useMemo(
    () => StatService.hasAssignedCards(nextStudyStat),
    [nextStudyStat],
  );

  const numberToReview: number = useMemo(
    () => StatService.numberOfCardsToReviewToday(nextStudyProgress),
    [nextStudyProgress],
  );

  const reviewStatus = useMemo(
    () =>
      pipe(
        additionalCards,
        O.map((s: ReviewAdditionalCards) => s.mode === 'Review'),
        O.getOrElse(() => false),
      ),
    [additionalCards],
  );

  const titleMessage = useMemo(
    () =>
      pipe(
        user,
        O.chain((u: User) => u.username),
        O.fold(
          () => 'welcome back!',
          (username: string) =>
            username.length > 0
              ? `welcome back, ${username}!`
              : 'welcome back!',
        ),
      ),
    [user],
  );

  const subtitleMessage = useMemo(() => {
    if (inProgress) {
      return [];
    } else if (!hasAssignedCards) {
      return ['Your content is coming soon!'];
    } else if (numberToReview === 0) {
      return reviewStatus
        ? ['You’ve got nothing to review!']
        : [
            'You’ve got nothing to review!',
            'Enjoy your day and see you',
            'next time!',
          ];
    } else {
      const cardsWord = numberToReview > 1 ? 'cards' : 'card';
      return [`You’ve got ${numberToReview} ${cardsWord} to review today.`];
    }
  }, [hasAssignedCards, inProgress, numberToReview, reviewStatus]);

  const [isNotificationsShown, setNotificationsShown] =
    useRecoilState(notificationsState);

  const cardsToReview = useMemo(() => {
    return pipe(
      nextStudyProgress,
      O.map((s) => (s.progress[s.mode as keyof typeof s.progress])),
      O.map((p) => p.total),
      O.getOrElse(() => 0),
    );
  }, [nextStudyProgress]);

  const message = useMemo(() => {
    return {
      title: [titleMessage],
      subtitle: subtitleMessage,
    } as MessageModel;
  }, [titleMessage, subtitleMessage]);

  const MessageContent = useCallback(() => {
    return <MessageBox title={message.title} subtitle={message.subtitle} />;
  }, [message]);

  const isAdditionalCardsButton = useMemo(() => {
    return pipe(
      additionalCards,
      O.map((ac) => ac.mode === 'Review'),
      O.getOrElse(() => false),
    );
  }, [additionalCards]);

  const onGetStartedClick = useCallback(() => {
    navigate('/study');
  }, [navigate]);

  const AdditionalCardsButton = useCallback(
    () => (
      <div className={styles.buttonsContainer}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={onGetStartedClick}>
          Review {cardsToReview} more
        </Button>
      </div>
    ),
    [classes, onGetStartedClick, cardsToReview],
  );

  const onError = (e: BSError): void => {
    debug('WelcomeBackScreen error', e);
    setInProgress(false);
  };

  const onOk = (): void => {
    setInProgress(false);
  };

  const processNextStep = useCallback(
    (ns: NextStep): BSTask<void> =>
      BSTaskFromIO(() => {
        setNextStep(O.some(ns));
      }),
    [setNextStep],
  );

  const setError = useError();

  const fetchNextStep = useCallback(() => {
    return pipe(
      BSTaskFromIO(() => setInProgress(true)),
      TE.chain(() => StudyService.nextStep(O.none)),
      TE.chain(processNextStep),
      TE.mapLeft(setError),
      T.map(E.fold(onError, onOk)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processNextStep]);

  const fetchNotification = useCallback(() => {
    return pipe(
      UserService.getNotification(),
      TE.map((n: BSNotification) => {
        debug(`NOTIFICATION: ${O.getOrElse(() => 'empty')(n.message)}`);
        setNotification(n.message);
      }),
      ignoreTaskEither,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNotification]);

  const onLoad: () => Promise<void> = useCallback(async () => {
    const fetchNextStepTask = fetchNextStep();
    await executeTask(fetchNextStepTask);
  }, [fetchNextStep]);

  const GetStartedControls = useCallback(
    () => (
      <div className={styles.buttonsContainer}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={onGetStartedClick}>
          Start
        </Button>
      </div>
    ),
    [classes, onGetStartedClick],
  );

  useShortcuts([' '], () => {
    if (isNotificationsShown) onGetStartedClick();
    else setNotificationsShown(true);
  });
  useShortcuts(['p'], () => {
    navigate('/settings');
  });

  const GetStartedSection = useCallback(
    () =>
      inProgress || numberToReview === 0 ? (
        isAdditionalCardsButton ? (
          <AdditionalCardsButton />
        ) : null
      ) : (
        <GetStartedControls />
      ),
    [
      inProgress,
      numberToReview,
      GetStartedControls,
      AdditionalCardsButton,
      isAdditionalCardsButton,
    ],
  );

  const shortcutsMessage = (
    <span>
      <span style={{fontWeight: 700}}>*New* </span>
      You can now use keyboard shortcuts to get through your content twice as
      fast! <br />
      Push your spacebar to get started today
    </span>
  );

  const keyboardShortcutsShownKey = 'KeyboardShortcutsShown';

  const setInitial = () => {
    window.localStorage.setItem(keyboardShortcutsShownKey, '1');
  };

  useEffect(() => {
    debug('ENTER WELCOME');
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    document.title = "Today's Cards – Blank Slate";
  }, []);

  const getShortcutsNotificationShowCount = () => {
    try {
      const localStorage = window.localStorage.getItem(
        keyboardShortcutsShownKey,
      );
      if (typeof localStorage === 'undefined' || localStorage === null) {
        return 0;
      }
      try {
        const localStorageInt = parseInt(localStorage);
        return localStorageInt;
      } catch {
        return 0;
      }
    } catch {
      return 0;
    }
  };

  const [shortcutsShown] = useState(getShortcutsNotificationShowCount());

  const setNewShortcutsShownCount = () => {
    try {
      window.localStorage.setItem(
        keyboardShortcutsShownKey,
        `${shortcutsShown + 1}`,
      );
    } catch {
      setInitial();
    }
  };

  useEffect(() => {
    (async function foo() {
      const fetchNotificationTask = fetchNotification();
      await executeTask(fetchNotificationTask);
    })();
  }, [fetchNotification]);

  return (
    <div className={styles.root}>
      {!isNotificationsShown &&
        (O.isSome(notification) ? (
          <NotificationPopup
            redNumbers
            message={O.toNullable(notification)}
            onEnd={() => setShortcutsNotificationVisible(true)}
          />
        ) : (
          shortcutsShown < 5 && (
            <NotificationPopup
              showCloseCheckmark
              message={shortcutsMessage}
              onEnd={() => {
                setNewShortcutsShownCount();
                setNotificationsShown(true);
              }}
            />
          )
        ))}

      {shortcutsShown < 5 &&
        shortcutsNotificationVisible &&
        !isNotificationsShown && (
          <NotificationPopup
            showCloseCheckmark
            message={shortcutsMessage}
            onEnd={() => {
              setShortcutsNotificationVisible(false);
              setNewShortcutsShownCount();
              setNotificationsShown(true);
            }}
          />
        )}

      <div className={styles.content}>
        <div className={styles.messageContent}>
          <MessageContent />
          <div className={styles.subMessageSpace} />
        </div>
        <GetStartedSection />
      </div>
    </div>
  );
};

export default WelcomeBack;
