import RestService from './RestService';
import {pipe} from 'fp-ts/lib/function';
import {BSTask} from '../model/types';
import * as TE from 'fp-ts/lib/TaskEither';
import * as O from 'fp-ts/lib/Option';
import {nextStepTOResponseDecoder} from './to/NextStepTO';
import {NextStep} from '../model/domain/NextStep';
import NextStepConverter from './converter/NextStepConverter';
import DeckConverter from './converter/DeckConverter';
import CardHtmlConverter from './converter/CardHtmlConverter';
import DeckCardsConverter from './converter/DeckCardsConverter';
import {Stat} from '../model/domain/Stat';
import {StatTO} from './to/StatTO';
import StatConverter from './converter/StatConverter';
import { deckCardsTOResponseDecoder, deckTOResponseDecoder, resourcesItemsTOResponseDecoder } from './to/DeckTO';
import { DeckCard, DeckEntry, ResourcesItem } from '../model/domain/Deck';
import { CardHtml } from '../model/domain/CardHtml';
import { cardHtmlTOResponseDecoder } from './to/CardHtmlTO';
import ResourcesConverter from './converter/ResourcesConverter';

const nextStep = (stat: O.Option<Stat>): BSTask<NextStep> => {
  const url = '/app/study';
  const body: undefined | StatTO = pipe(
    stat,
    O.map<Stat, StatTO>((s: Stat) => StatConverter.fromDomain(s)),
    O.getOrElse<undefined | StatTO>(() => undefined),
  );

  return pipe(
    RestService.put(url, body),
    nextStepTOResponseDecoder,
    TE.map(NextStepConverter.toDomain),
  );
};

const getUserLibraryDecks = (): BSTask<DeckEntry[]> => {
  const url = '/app/library/decks?deckType=0';

  return pipe(
    RestService.get(url),
    deckTOResponseDecoder,
    TE.map(DeckConverter.toDomain),
  );
};

const getUserLibraryDeckCards = (deckId: string): BSTask<DeckCard[]> => {
  const url = `/app/library/decks/${deckId}`;

  return pipe(
    RestService.get(url),
    deckCardsTOResponseDecoder,
    TE.map(DeckCardsConverter.toDomain),
  );
};

const getUserLibraryCard = (cardId: string): BSTask<CardHtml> => {
  const url = `/app/library/cards/${cardId}`;

  return pipe(
    RestService.get(url),
    cardHtmlTOResponseDecoder,
    TE.map(CardHtmlConverter.toDomain),
  );
};

const getUserLibraryResources = (): BSTask<ResourcesItem[]> => {
  const url = '/app/library/extra-resources';

  return pipe(
    RestService.get(url),
    resourcesItemsTOResponseDecoder,
    TE.map(ResourcesConverter.toDomain),
  );
};

export default {
  nextStep: nextStep,
  getUserLibraryDecks,
  getUserLibraryDeckCards,
  getUserLibraryCard,
  getUserLibraryResources
};
