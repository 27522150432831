export const QuestionTypeEnum = {
  Unknown: 0,
  TrueFalse: 1,
  MultipleChoice: 2,
  SingleRecall: 3,
  Ordered: 4,
  Unordered: 5,
  Survey: 6,
  Text: 7,
} as const;

export type QuestionTypeEnum = typeof QuestionTypeEnum[keyof typeof QuestionTypeEnum];
