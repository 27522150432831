import {BSTask} from '../model/types';
import * as TE from 'fp-ts/lib/TaskEither';
import * as O from 'fp-ts/lib/Option';
import {pipe} from 'fp-ts/lib/function';
import StudyApi from '../api/StudyApi';
import {NextStep} from '../model/domain/NextStep';
import {Stat} from '../model/domain/Stat';
import {debugIO} from '../util/logger';
import { DeckCard, DeckEntry, ResourcesItem } from '../model/domain/Deck';
import { CardHtml } from '../model/domain/CardHtml';

const nextStep = (stat: O.Option<Stat>): BSTask<NextStep> => {
  return pipe(
    StudyApi.nextStep(stat),
    TE.chainFirst((ns: NextStep) => debugIO('NextStep', ns)),
  );
};

const getUserLibraryDecks = (): BSTask<DeckEntry[]> => {
  return pipe(StudyApi.getUserLibraryDecks());
}

const getUserLibraryDeckCards = (deckId: string): BSTask<DeckCard[]> => {
  return pipe(StudyApi.getUserLibraryDeckCards(deckId));
}

const getUserLibraryCard = (cardId: string): BSTask<CardHtml> => {
  return pipe(StudyApi.getUserLibraryCard(cardId));
}

const getUserLibraryResources = (): BSTask<ResourcesItem[]> => {
  return pipe(StudyApi.getUserLibraryResources());
}


export default {
  nextStep,
  getUserLibraryDecks,
  getUserLibraryDeckCards,
  getUserLibraryCard,
  getUserLibraryResources
};
